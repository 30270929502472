import { FormTemplate, BaseTemplateResponse } from "@/ORM/Global/FormTemplate.js";


class Team {
    constructor(data = {}) {
        this.id = data.id || "";;
        this.idea = data.idea ? data.idea : null;
        this.hackathon = data.hackathon ? data.hackathon : null;
        this.name = data.name ? data.name : null;
        this.members =
            data.members && data.members.edges
                ? data.members.edges.map((edge) => new TeamMember(edge.node))
                : [];
        this.join_requests =
            data.join_requests && data.join_requests.edges
                ? data.join_requests.edges.map(
                      (edge) => new TeamJoinRequest(edge.node)
                  )
                : [];
        this.team_resources =
            data.team_resources && data.team_resources.edges
                ? data.team_resources.edges.map(
                      (edge) => new TeamResource(edge.node)
                  )
                : [];
        this.submissions =
            data.submissions && data.submissions.edges
                ? data.submissions.edges.map(
                      (edge) => new Submission(edge.node)
                  )
                : [];
    }
}

class TeamMember {
    constructor(data = {}) {
        this.id = data.id || "";;
        this.hackathon = data.hackathon ? data.hackathon : null;
        this.team = data.team ? data.team : null;
        this.profile = data.profile ? data.profile : null;
        this.status = data.status ? data.status : null;
        this.role = data.role ? data.role : null;
    }
}

class TeamJoinRequest {
    constructor(data = {}) {
        this.id = data.id || "";;
        this.team = data.team ? data.team : null;
        this.profile = data.profile ? data.profile : null;
        this.status = data.status ? data.status : null;
    }
}

class TeamResource {
    constructor(data = {}) {
        this.id = data.id || "";;
        this.team = data.team ? data.team : null;
        this.url = data.url ? data.url : null;
        this.name = data.name ? data.name : null;
        this.team_resource_template_item = data.team_resource_template_item
            ? data.team_resource_template_item
            : null;
    }
}

class Submission {
    constructor(data = {}) {
        this.id = data.id || "";
        this.team = data.team ? new Team(data.team) : null;
        this.response = data.response ? data.response : null;
        this.competition_category = data.competition_category
            ? data.competition_category
            : null;
        this.submitted =
            typeof data.submitted === "boolean" ? data.submitted : null;
        this.late_submission =
            typeof data.lateSubmission === "boolean"
                ? data.lateSubmission
                : null;
        this.submitted_time =
            typeof data.submittedTime === "boolean" ? data.submittedTime : null;
        this.judging_status = typeof data.judgingStatus === "boolean"
        ? data.judgingStatus
        : null;
        this.my_judging_status = typeof data.myJudgingStatus === "boolean"
        ? data.myJudgingStatus
        : null;
        this.judging_progress =
            typeof data.judgingProgress === "number"
                ? data.judgingProgress
                : null;
        this.total_judges =
            typeof data.totalJudges === "number" ? data.totalJudges : null;
            this.template = data.template ? new FormTemplate(data.template) : null;
            this.my_score = typeof data.myScore === "number" ? data.myScore : null;
            this.max_score = typeof data.maxScore === "number" ? data.maxScore : null;
            this.average_score = typeof data.averageScore === "number" ? data.averageScore : null;
    
        }
}

const modelMapping = {
    allHackathonTeamSubmission: Submission,
    hackathonTeamSubmission: Submission,
    // Add other mappings here as needed
};
function processTeamRelayData(inputObject) {
    for (let key in inputObject.data) {
        if (modelMapping[key]) {
            const ModelClass = modelMapping[key];
            // Handle array of instances (for "all" prefixed keys)
            if (key.startsWith("all") && inputObject.data[key].edges) {
                return inputObject.data[key].edges.map(
                    (edge) => new ModelClass(edge.node)
                );
            }

            // Handle single instance
            return new ModelClass(inputObject.data[key]);
        }
    }
    return null;
}

export {
    processTeamRelayData,
    Team,
    TeamMember,
    TeamJoinRequest,
    TeamResource,
    Submission,
};
